import _slicedToArray from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;
import React from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Link from 'next/link';
import { logout } from '../../services/security.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { PremiumIcon } from '../Icon';
import { Crown } from '../Icon';
import { isBrowser } from '../../helpers/browser';
import Popover from '../Popover';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import TheatersOutlinedIcon from '@material-ui/icons/TheatersOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
var useStyles = makeStyles(function (theme) {
  return {
    menuItemLink: {
      textDecoration: 'unset !important',
      color: 'unset',
      '& .MuiListItem-button:hover': {
        backgroundColor: '#FED102'
      }
    },
    menuItem: {
      padding: '6px 16px',
      display: 'flex',
      justifyContent: 'flex-start',
      // textTransform: 'capitalize',
      fontSize: 14,
      // fontWeight: 300,
      lineHeight: '19px',
      fontFamily: 'Open sans, sans-serif',
      color: '#414042',
      // letterSpacing: '1.4px',
      '&:hover': {
        backgroundColor: '#f9f9f9'
      },
      marginBottom: 4,
      // paddingTop: 0,
      // paddingBottom: 0
      '& span': {
        marginLeft: 10
      }
    },
    userInfo: {
      fontSize: 12,
      marginLeft: 6,
      textTransform: 'initial'
    },
    avatar: {
      fontSize: 12,
      width: '28px',
      height: '28px',
      textTransform: 'uppercase',
      fontWeight: 700
    },
    userMenuButton: {
      padding: '0',
      borderRadius: '50%',
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& svg': {
        left: 'unset',
        right: 14,
        top: 18
      }
    },
    list: {
      // paddingBottom: 20,
      '@media only screen and (max-width: 768px)': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    avatarIn: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px 16px 0px',
      marginBottom: 4,
      '@media only screen and (max-width: 768px)': {
        justifyContent: 'center'
      },
      '& svg': {
        left: 'unset',
        right: 27,
        top: 32
      }
    },
    paper: {
      boxShadow: '0px 3px 18px #00000029',
      borderRadius: 12,
      padding: 0
    },
    premiumIcon: {
      position: 'absolute',
      top: 32,
      left: -6,
      'svg': {
        width: 25,
        height: 25,
        top: 18,
        left: 'unset',
        right: 14
      }
    }
  };
});

var UserMenu = function UserMenu(_ref) {
  var user = _ref.user;
  var classes = useStyles();

  var _useAuthentication = useAuthentication(),
      dispatch = _useAuthentication.dispatch,
      plan = _useAuthentication.plan;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var handleClick = function handleClick(event) {
    setOpen(true);
  };

  var handleClose = function handleClose() {
    setOpen(false);
  };

  var handleNavTutorial = function handleNavTutorial() {
    setOpen(false);
    window.open('https://support.woxo.tech/en', '_blank');
  };

  var handleLogout = function handleLogout() {
    logout().then(function () {
      dispatch({
        type: 'LOGOUT',
        payload: null
      });
    });
  };

  return __jsx("div", {
    className: "user-menu"
  }, __jsx(Popover, {
    open: open,
    onClose: function onClose() {
      return setOpen(false);
    },
    content: __jsx(Paper, {
      className: classes.paper
    }, __jsx(MenuList, {
      id: "menu-list-grow",
      className: classes.list
    }, __jsx(Link, {
      href: "/dashboard?tab=settings"
    }, __jsx(MenuItem, {
      onClick: handleClose,
      className: classes.menuItem
    }, __jsx(SettingsOutlinedIcon, null), __jsx("span", null, "My Account"))), __jsx(Link, {
      href: "/dashboard?tab=videos"
    }, __jsx(MenuItem, {
      onClick: handleClose,
      className: classes.menuItem
    }, __jsx(PlayCircleOutlineOutlinedIcon, null), __jsx("span", null, "My Videos"))), __jsx(Link, {
      href: "/dashboard?tab=widgets"
    }, __jsx(MenuItem, {
      onClick: handleClose,
      className: classes.menuItem
    }, __jsx(WidgetsOutlinedIcon, null), __jsx("span", null, "My Widgets"))), __jsx(Link, {
      href: "/dashboard?tab=assets"
    }, __jsx(MenuItem, {
      onClick: handleClose,
      className: classes.menuItem
    }, __jsx(FolderOutlinedIcon, null), __jsx("span", null, "My Assets"))), __jsx(MenuItem, {
      onClick: handleNavTutorial,
      className: classes.menuItem
    }, __jsx(TheatersOutlinedIcon, null), __jsx("span", null, "Tutorials")), __jsx(MenuItem, {
      onClick: handleLogout,
      className: classes.menuItem
    }, __jsx(ExitToAppIcon, null), __jsx("span", null, "Logout"))))
  }, __jsx(Button, {
    onClick: handleClick,
    color: "inherit",
    className: classes.userMenuButton
  }, user && user.picture && __jsx(Avatar, {
    className: classes.avatar,
    alt: user.username,
    src: user.picture
  }), user && !user.picture && __jsx(Avatar, {
    className: classes.avatar
  }, user.username.substring(0, 2)), plan && __jsx(Crown, {
    className: classes.premiumIcon
  }))));
};

UserMenu.defaultProps = {
  user: null
};
export default UserMenu;