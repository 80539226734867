import _slicedToArray from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import clsx from 'clsx';
import { Box, Button, MenuItem, MenuList } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './styles';
import Popover from '../Popover';
import { useAuthentication } from '../../hooks/useAuthentication';
import useAppEvent from '../../hooks/useAppEvent';
import { remove } from '../../services/notification.service';
dayjs.extend(relativeTime);

var UserNotification = function UserNotification(_ref) {
  var className = _ref.className,
      containerClassName = _ref.containerClassName;
  var classes = useStyles();
  var handleNotification = useAppEvent();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var _useAuthentication = useAuthentication(),
      _useAuthentication$no = _useAuthentication.notifications,
      data = _useAuthentication$no.data,
      count = _useAuthentication$no.count,
      dispatch = _useAuthentication.dispatch;

  React.useEffect(function () {
    if (count === 0) setOpen(false);
  }, [count]);

  var handleClick = function handleClick(event) {
    if (data.length) setOpen(true);
  };

  var handleClose = function handleClose() {
    setOpen(false);
  };

  var handleRemove = function handleRemove(e, n) {
    e.stopPropagation();
    remove(n).then(function () {
      dispatch({
        type: 'REMOVE_NOTIFICATIONS',
        notification: n._id
      });
    });
  };

  if (!data.length) {
    return null;
  }

  return __jsx(Box, {
    position: "relative",
    className: clsx(containerClassName, 'notification-container')
  }, __jsx(Popover, {
    open: open,
    onClose: function onClose() {
      return setOpen(false);
    },
    content: __jsx(Box, {
      className: classes.listPopoverContainer
    }, __jsx(MenuList, {
      id: "menu-list-grow",
      className: classes.list
    }, data.map(function (n) {
      return __jsx(MenuItem, {
        key: n._id,
        className: classes.menuItem,
        onClick: function onClick() {
          handleClose();
          handleNotification(n);
        }
      }, __jsx(FiberManualRecordIcon, {
        className: "dot"
      }), __jsx(Box, null, __jsx("div", {
        dangerouslySetInnerHTML: {
          __html: n.message
        }
      }), __jsx(Box, {
        className: classes.date
      }, dayjs().locale('en').to(dayjs(n.createdAt)))), __jsx(DeleteIcon, {
        className: "trash",
        onClick: function onClick(e) {
          handleRemove(e, n);
        }
      }));
    })))
  }, __jsx(Button, {
    color: "inherit",
    onClick: handleClick,
    className: clsx(classes.buttonPopover, data.length ? 'primary' : '', className)
  }, !!data.length && __jsx(NotificationsActiveIcon, null))), !!data.length && __jsx("span", {
    className: classes.badge
  }, count));
};

UserNotification.defaultProps = {
  className: null,
  containerClassName: null
};
export default UserNotification;