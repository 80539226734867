import _slicedToArray from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;
import React from 'react';
import axios from 'axios';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) {
  return {
    linkTitle: {
      textTransform: 'uppercase',
      fontSize: 16,
      fontFamily: 'Open sans, sans-serif',
      fontWeight: 800
    },
    link: {
      fontSize: 16,
      fontWeight: '300',
      fontFamily: 'Open sans, sans-serif',
      margin: '12px 0px',
      '& a': {
        color: '#fff',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '@media only screen and (max-width: 425px)': {
        textAlign: 'center'
      }
    }
  };
});

var PostsList = function PostsList() {
  var classes = useStyles();

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      posts = _React$useState2[0],
      setPosts = _React$useState2[1];

  React.useEffect(function effectFunction() {
    var postsList = JSON.parse(sessionStorage.getItem('postsList'));
    var today = new Intl.DateTimeFormat('en-Us', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(new Date());

    var loadPosts = function loadPosts() {
      axios.get('/rewrites/api/blog/list').then(function (value) {
        setPosts(value.data.slice(0, 3));
        sessionStorage.setItem('postsList', JSON.stringify({
          today: today,
          posts: value.data.slice(0, 3)
        }));
      });
    };

    if (postsList && postsList.today === today) {
      setPosts(postsList.posts);
    } else {
      loadPosts();
    }
  }, []);

  var createMarkup = function createMarkup(html) {
    return {
      __html: html
    };
  };

  return __jsx(React.Fragment, null, !!posts.length && __jsx(Typography, {
    className: classes.linkTitle
  }, "Top blog posts"), posts.map(function (v) {
    return __jsx(Typography, {
      key: v.id,
      className: classes.link
    }, __jsx("a", {
      href: v.link,
      target: "_blank",
      rel: "noreferrer" // eslint-disable-next-line react/no-danger
      ,
      dangerouslySetInnerHTML: createMarkup(v.title.rendered)
    }));
  }));
};

export default PostsList;