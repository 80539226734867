var __jsx = React.createElement;
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography, Container } from '@material-ui/core';
import { LinkedinIcon, TwitterIcon, YoutubeIcon, FacebookFooterIcon, InstagramIcon } from '../Icon';
import PostsList from '../PostsList';
var useStyles = makeStyles(function (theme) {
  return {
    root: {
      backgroundColor: '#3d3d3d',
      color: '#fff',
      position: 'relative'
    },
    footerContainer: {
      paddingTop: '90px',
      paddingBottom: '45px'
    },
    smallFooterContainer: {
      paddingTop: '50px',
      paddingBottom: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      '@media only screen and (max-width: 650px)': {
        flexDirection: 'column'
      }
    },
    iconsContainers: {
      marginBottom: 40
    },
    copyright: {
      fontSize: 16,
      fontWeight: '300',
      color: '#fff',
      marginTop: 40,
      '@media only screen and (max-width: 425px)': {
        textAlign: 'center'
      }
    },
    iconTwitter: {
      fontSize: 42,
      marginRight: 24,
      '@media only screen and (max-width: 425px)': {
        marginRight: 12
      }
    },
    iconLinkedin: {
      fontSize: 42
    },
    linkTitle: {
      textTransform: 'uppercase',
      fontSize: 16,
      fontFamily: 'Open sans, sans-serif',
      fontWeight: 800
    },
    linksContainer: {
      justifyContent: 'space-between'
    },
    link: {
      fontSize: 16,
      fontWeight: '300',
      fontFamily: 'Open sans, sans-serif',
      margin: '12px 0px',
      '& a': {
        color: '#fff',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '@media only screen and (max-width: 425px)': {
        textAlign: 'center'
      }
    },
    grid: {
      '@media only screen and (max-width: 425px)': {
        marginBottom: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    iconsRow: {
      '@media only screen and (max-width: 425px)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  };
});

var Footer = function Footer(_ref) {
  var className = _ref.className,
      showSmallFooter = _ref.showSmallFooter;
  var classes = useStyles();
  return __jsx(Box, {
    className: clsx(classes.root, className)
  }, showSmallFooter ? __jsx(Container, {
    className: classes.smallFooterContainer
  }, __jsx(Box, {
    className: classes.copyright,
    style: {
      marginTop: 0
    }
  }, "\xA9", " ".concat(new Date().getFullYear(), " "), "All Rights Reserved, WOXO"), __jsx(Box, null, __jsx(Typography, {
    className: classes.link,
    style: {
      marginLeft: 50,
      marginRight: 50
    }
  }, __jsx("a", {
    href: "/legal/terms-of-service"
  }, "Terms of Service"))), __jsx(Box, null, __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "/legal/privacy-policy"
  }, "Privacy Policy")))) : __jsx(Container, {
    className: classes.footerContainer
  }, __jsx(Grid, {
    container: true
  }, __jsx(Grid, {
    item: true,
    xs: 12,
    lg: 4,
    xl: 4,
    className: classes.iconsContainers
  }, __jsx(Box, {
    className: classes.iconsRow
  }, __jsx("a", {
    href: "https://www.facebook.com/woxotech/",
    target: "_blank"
  }, __jsx(FacebookFooterIcon, {
    className: classes.iconTwitter,
    width: "42.569",
    height: "49.155",
    viewBox: "0 0 42.569 49.155"
  })), __jsx("a", {
    href: "https://www.instagram.com/woxo.tech/",
    target: "_blank"
  }, __jsx(InstagramIcon, {
    className: classes.iconTwitter,
    width: "42.569",
    height: "49.155",
    viewBox: "0 0 42.569 49.155"
  })), __jsx("a", {
    href: "https://www.linkedin.com/company/front10/",
    target: "_blank"
  }, __jsx(LinkedinIcon, {
    className: classes.iconTwitter,
    width: "42.569",
    height: "49.155",
    viewBox: "0 0 42.569 49.155"
  })), __jsx("a", {
    href: "https://www.youtube.com/channel/UCUjYDLLKFfBbTbaiBSFT3_A",
    target: "_blank"
  }, __jsx(YoutubeIcon, {
    className: classes.iconTwitter,
    width: "42.569",
    height: "49.155",
    viewBox: "0 0 42.569 49.155"
  })))), __jsx(Grid, {
    item: true,
    xs: 12,
    lg: 8,
    xl: 8
  }, __jsx(Grid, {
    container: true,
    className: classes.linksContainer
  }, __jsx(Grid, {
    item: true,
    className: classes.grid,
    xs: 12,
    sm: 3
  }, __jsx(Typography, {
    className: classes.linkTitle
  }, "Resources"), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://support.woxo.tech/en/",
    target: "_blank"
  }, "Support Center")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://woxo.tech/blog/",
    target: "_blank"
  }, "Blog")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://www.notion.so/Woxo-s-Roadmap-8804e18c43a04f8ca29a141621238f24",
    target: "_blank"
  }, "Roadmap"))), __jsx(Grid, {
    item: true,
    className: classes.grid,
    xs: 12,
    sm: 3
  }, __jsx(Typography, {
    className: classes.linkTitle
  }, "Company"), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://front10.com",
    target: "_blank"
  }, "Home")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://front10.com/about.html",
    target: "_blank"
  }, "About")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://front10.com/careers.html",
    target: "_blank"
  }, "Careers")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "https://front10.com/contact.html",
    target: "_blank"
  }, "Contact Us"))), __jsx(Grid, {
    item: true,
    className: classes.grid,
    xs: 12,
    sm: 3
  }, __jsx(Typography, {
    className: classes.linkTitle
  }, "Legal"), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "/legal/privacy-policy"
  }, "Privacy Policy")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "/legal/terms-of-service"
  }, "Terms of Service")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "/legal/trademark-policy"
  }, "Trademark Policy")), __jsx(Typography, {
    className: classes.link
  }, __jsx("a", {
    href: "/legal/sla"
  }, "SLA"))), __jsx(Grid, {
    item: true,
    className: classes.grid,
    xs: 12,
    sm: 3
  }, __jsx(PostsList, null))))), __jsx(Box, {
    className: classes.copyright
  }, "\xA9", " ".concat(new Date().getFullYear(), " "), "All Rights Reserved, WOXO")));
};

Footer.defaultProps = {
  className: null,
  showSmallFooter: false
};
export default Footer;