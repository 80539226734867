var __jsx = React.createElement;
import React from 'react';
import Script from 'react-load-script';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
var useStyles = makeStyles(function (theme) {
  return {
    tutorial: {
      color: '#4686FF',
      textDecoration: 'underline #4686FF',
      cursor: 'pointer'
    }
  };
});

var Tutorial = function Tutorial(_ref) {
  var text = _ref.text,
      className = _ref.className;
  var classes = useStyles();
  return __jsx(React.Fragment, null, __jsx("span", {
    className: "wistia_embed wistia_async_w6vijm3rmc popover=true popoverAnimateThumbnail=true popoverContent=link",
    style: {
      display: 'inline',
      position: 'relative'
    }
  }, __jsx("span", {
    className: clsx(classes.tutorial, className)
  }, text)), __jsx(Script, {
    url: "https://fast.wistia.com/embed/medias/w6vijm3rmc.jsonp",
    attributes: {
      async: true
    }
  }), __jsx(Script, {
    url: "https://fast.wistia.com/assets/external/E-v1.js",
    attributes: {
      async: true
    }
  }));
};

Tutorial.defaultProps = {
  text: 'tutorial.',
  className: null
};
export default Tutorial;